{
  "base": {
    "pabloarts": "i-Scream arts Co.,Ltd.",
    "kakaoChannel": "kakao Channel",
    "contactUs": "Đặt câu hỏi",
    "instagram": "Instagram",
    "brandResource": "Tài nguyên thương hiệu",
    "comingSoon": "Sẽ đến sớm",
    "gotoSite": "Các phím tắt",
    "family": "Công ty gia đình",
    "doitLater": "Please try again in a few minutes later",
    "artbonbon": "artbonbon"
  },
  "meta": {
    "title": "i-Scream Arts Co.,Ltd.",
    "keywords": "i-Scream arts Co.,Ltd., i-Scream, i-Scream arts Company, công ty giáo dục nghệ thuật, Công ty Art Bonbon, Giáo dục nhân tài tương lai kỹ thuật số, nghệ thuật edutech, Nền tảng dữ liệu lớn AI, nghệ thuật trẻ em, i-Scream arts Center",
    "description": "i-Scream arts Co.,Ltd. \t&#124; Một lĩnh vực giáo dục mới với nghệ thuật"
  },
  "footer": {
    "address": "i-Scream arts Co.,Ltd., tầng 18, số 512 Teheran-ro, quận Kangnam, thành phố Seoul",
    "tel": "SĐT",
    "contactUs": "Mọi thắc mắc xin gửi về"
  },
  "company": {
    "jumbotron": {
      "introduce1": "",
      "introduce2": "Doanh nghiệp Edutech (Giáo dục kỹ thuật) hỗ trợ để trẻ có thể tự mình đình hình khuôn mẫu cuộc sống của chính mình, đồng thời trở thành khuôn mẫu cho xã hội",
      "nextSection": "Bỏ qua phần tiếp theo"
    },
    "philosophy": {
      "headDescription": "Company philosophy",
      "headTitle": "Mọi trẻ em từ khi sinh ra đều đã là một nghệ sĩ",
      "description": "Mọi trẻ em từ khi sinh ra đều đã là một nghệ sĩ. <br/> Ngay từ khi mọi đứa trẻ được tiếp cận với các công cụ, chúng có thể chơi, thể hiện, thủ công, đập phá, thể hiện nội tâm và thanh lọc cảm xúc của chúng.. Bản thân nghệ thuật là một cách để trẻ em thể hiện suy nghĩ và cảm xúc của mình, và nó là cách hiệu quả nhất để giao tiếp với người khác."
    },
    "question": "<span>\"Art is the key to your mind.\"<br></span><span style='font-size:55%; line-height:80%'>Art is the ideal medium for expressing your creativity<br>because it\nintuitively communicates your innermost thoughts.</span>",
    "questionMobile": "<span>\"Art is the key<br>to your mind.\"<br></span><span style='font-size:55%; line-height:80%'>Art is the ideal medium<br>for expressing your creativity<br>because it\nintuitively communicates your innermost thoughts.</span>",
    "motivation": {
      "headDescription": "Work Motivation",
      "headTitle": "Numerous studies point to art as a source of inspiration and creativity.",
      "resource": "Người giới thiệu",
      "resourceDescription0": "Barrett, Lisa Feldman. “Seven and a Half Lessons about the Brain”. 2021",
      "resourceDescription1": "Lee, Boo Yun. “A Study on The importance of Brain Development through Convergence of Art with STEAM Education”. KOREA SCIENCE & ART FORUM. 2014",
      "resourceDescription2": "Edwards, Betty. “Inner Imagery, Drawing on the Right Side of the Brain”. 2013",
      "resourceDescription3": "Gardner, H. “Multiple Intelligence”. 2007",
      "description": "Art conveys meaning through images rather than words, which allows for\nthe unrestricted expansion of ideas. Image is the source of imagination,\nand imagination is intimately related to creativity. This is why it is crucial\nto improve your mental health from a young age by broadening your\nviews.",
      "sectionTitle": "Creative, convergent art education for native digital users",
      "sectionDescription": "Digital literacy, as a fundamental skill for locating relevant information\nand utilizing it on multiple digital platforms, is gaining importance in\nthe present day. This indicates that the significance of the ability to\nthink visually increases with time. It is quite difficult to convey creative\nknowledge in clear and concise language. Nevertheless, it is feasible\nwith art. The digital age necessitates more art and digital art instruction."
    },
    "competence": {
      "headDescription": "Core Competence",
      "headTitle": "Năng lực cạnh tranh cốt lõi",
      "section1": {
        "title": "Con đường để chúng ta phát triển",
        "word1": "Giáo dục nghệ thuật dành cho thiếu niên trên toàn thế giới",
        "word2": "Nuôi dưỡng trí sáng tạo, khả năng tưởng tượng",
        "word3": "Nuôi dưỡng kỹ năng giao tiếp",
        "word4": "Phán đoán tài năng, năng khiếu, định hướng tương lai, khả năng của trẻ",
        "word5": "Cửa ngõ bước vào thế giới nghệ thuật toàn cầu của trẻ",
        "word6": "Nâng cao kiến thức về kỹ thuật số cho trẻ",
        "word7": "Nuôi dạy trẻ khỏe mạnh bằng mỹ thuật"
      },
      "section2": {
        "title": "Công nghệ (Đặc biệt là bảo hộ tài sản trí tuệ)",
        "word1": "Drawing Tool",
        "word2": "Real Time Platform",
        "word3": "Interactive Curriculum",
        "word4": "AI Technology"
      },
      "section3": {
        "title": "Giáo trình giảng dạy đặc biệt",
        "word1": "Chương trình đào tạo toàn cầu theo từng độ tuổi",
        "word2": "Interactive Contents(Đăng ký bản quyền)",
        "word3": "Giáo trình nâng cao khả năng sáng tạo",
        "word4": "Nhiều giáo trình đa dạng với các nội dung như phát triển nhân cách, khoa học tự nhiên, môi trường, lịch sử, khoa học trên nền tảng mỹ thuật"
      }
    },
    "artBonbon": {
      "title": "We’re<br/>ArtBonbon",
      "readMore": "Xem chi tiết",
      "introductionPDF": "Giới thiệu Art Bonbon(PDF)",
      "freeTrial": "Drawing Tool\nFree Trial(iOS)",
      "description": "Art Bonbon là dịch vụ giảng dạy sáng tạo tổng hợp toàn cầu đầu tiên trên thế giới được phát triển bởi i-Scream arts Co.,Ltd.<br/>\nArt Bonbon không đơn thuần chỉ là dịch vụ dạy mỹ thuật.<br/>Thông qua việc thể hiện ý tưởng suy nghĩ sáng tạo của bản thân bằng hoạt động nghệ thuật mỹ thuật có nền tảng công nghệ, chúng tôi giúp nuôi dưỡng những nhân tài cho tương lai trong tất cả các lĩnh vực như văn hóa, nghệ thuật, khoa học, toán học, nhân văn v.v. <br/><br/>Art Bonbon là dịch vụ giáo dục live(thời gian thực) 1:1(1 giáo viên 1 học sinh) hoặc 1:N (1 giáo viên, nhiều học sinh).<br/>Giáo trình của chương trình học gồm 3 phần Brain/Technic/Mind tùy theo độ tuổi và có thể lựa chọn."
    },
    "curiosity": {
      "title": "A Completely Fresh Start for Creativity",
      "description": "In the future, our children will rule the globe with creative ideas.<br/>Art BonBon seeks to assist children extend their imaginations without\nbounds via the use of art that fosters creativity and imagination in early\nlife. We genuinely hope that our education would enable the youngsters\nto become innovative global leaders.<br/><br/>Through art, we are poised to maximize children's creativity and imagination, as well as develop a super-personalized education curriculum based on the world's first patented real-time education data utilizing experts and AI technology.",
      "readMore": "Xem thêm"
    },
    "press": {
      "title": "i-Scream arts In Press",
      "more": "Xem thêm tin tức khác",
      "empty": "Không có bài báo nào cả."
    },
    "language": {
      "ko": "Hàn Quốc",
      "en": "Tiếng Anh"
    }
  },
  "brand": {
    "title": "Giới thiệu CI & BI của i-Scream arts Co.,Ltd.",
    "download": {
      "ai": "Tải AI",
      "pdf": "Tải PDF",
      "korean": "Tải về tiếng Hàn",
      "english": "Tải về tiếng Anh"
    },
    "ci": {
      "title": "i-Scream arts Co.,Ltd.",
      "description": "i-Scream arts Co.,Ltd. là một doanh nghiệp Edutech nỗ lực để cung cấp một nền giáo dục định hướng tương lai, một nền giáo dục có thể nuôi dưỡng sức khỏe tinh thần và tính sáng tạo thông qua mỹ thuật và đối thoại chứ không đơn thuần chỉ là giảng dạy mỹ thuật. Chúng tôi đang điều hành dịch vụ giáo dục mỹ thuật Online Art Bonbon, chuỗi trung tâm nhượng quyền giảng dạy Mỹ thuật On/offline i-Scream Arts Center, Trụ sở i-Scream Arts Center tại phường Daechi quận Kangnam/ Pangyo."
    },
    "bi": {
      "section1Title": "Art Bonbon",
      "section1Description": "Bonbon trong tiếng Pháp là từ được tạo thành từ việc lặp lại 2 lần từ Bon có nghĩa là 'tốt đẹp', là một từ ghép thú vị dùng để chỉ bánh kẹo, chocolate, jelly v.v. Chúng tôi sẽ cố gắng hết mình để các em có thể suy nghĩ và thể hiện cảm xúc bằng mỹ thuật, đồng thời tự mình thiết kế được ước mơ và hạnh phúc của bản thân mình.",
      "section2Title": "i-Scream arts Center",
      "section2Description": "i-Scream arts Center là một chuỗi trung tâm nhưỡng quyền chuyên giáo dục mỹ thuật On/Offline kết hợp phương pháp học truyền thống Chavruta của người Do Thái, cung cấp chương trình giảng dạy có khả năng nuôi dưỡng phát triển cảm giác tự tin vào bản thân thông qua việc tự do thể hiện bản thân. Là chuỗi trung tâm nhượng quyền On/Offline đầu tiên tại Hàn Quốc, nơi bạn có thể trải nghiệm phương pháp giáo dục kỹ thuật số Art Bonbon kết hợp với giáo trình Offline."
    }
  },
  "recruit": {
    "wanted": {
      "title": "Chúng tôi đang tìm kiếm bạn đồng hành để thử sức tại lĩnh vực dịch vụ nghệ thuật toàn cầu đầu tiên trên thế giới",
      "description": "Dịch vụ giáo dục mỹ thuật kỹ thuật số toàn cầu chưa từng có nay đã xuất hiện tại Hàn Quốc.<br/>Vượt qua ranh giới của việc giảng dạy mỹ thuật đơn thuần, chúng tôi liên kết với thế giới qua nhiều lĩnh vực đa dạng như triết học, vũ trụ, môi trường, khoa học, nuôi dưỡng nhân cách v.v. Năng lực cạnh tranh cốt yếu của chúng tôi là nội dung sáng tạo và công nghệ đặc biệt của riêng chúng tôi.<br/><br/><br/>Dịch vụ Art Bonbon của Digital i-Scream arts Company giúp nuôi dưỡng khả năng cạnh tranh, kiến thức về kỹ thuật số của trẻ em và đặt mục tiêu lan tỏa sức ảnh hưởng tốt đẹp đến thế hệ thiếu niên trên toàn thế giới.",
      "howto": "Cách chúng tôi làm việc"
    },
    "cards": {
      "people0": {
        "name": "Quản lý phòng chăm sóc khách hàng Park Eunhee",
        "team": "đội hỗ trợ khách hàng",
        "description": "Chúng tôi đang nỗ lực hết mình để lắng nghe và tiếp thu ý kiến của khách hàng với tư cách là 'tai' và 'miệng' của công ty."
      },
      "people1": {
        "name": "trợ lý tiếp thị Kim Sol Hee",
        "team": "đội tiếp thị chiến lược",
        "description": "Một cơ hội tuyệt vời để phát triển năng lực tiếp thị của lĩnh vực nghệ thuật và CNTT, tôi đang xây dựng một sự nghiệp phi thường."
      },
      "people2": {
        "name": "Nhân viên chế tác hình ảnh video Lee Minhee",
        "team": "phòng thương mại trực tiếp",
        "description": "Tôi thấy mình tiếp nhận được nguồn năng lượng tích cực nhờ những nội dung video sinh động và đầy sức sống mà mình làm ra."
      },
      "people3": {
        "name": "Nhà nghiên cứu AI,Kang Jaekoo",
        "team": "Phòng thí nghiệm AI",
        "description": "Chúng tôi đang nghiên cứu phát triển trí tuệ nhân tạo có khả năng nuôi dưỡng và phân tích khả năng sáng tạo, trí tưởng tượng, kỹ năng thể hiện bản thân của trẻ."
      },
      "people4": {
        "name": "Nhà thiết kế UX / UI, Nam Geumseo",
        "team": "Nhóm thiết kế",
        "description": "Trải nghiệm thiết kế ra dịch vụ mà cả trẻ em và người lớn cùng sử dụng, có sự kết hợp hài hòa giữa nghệ thuật và khoa học rất mới mẻ với tôi!"
      },
      "people5": {
        "name": "Quản lý nội dung, Hu Jiwon",
        "team": "Nhóm nội dung",
        "description": "Chúng tôi hạnh phúc khi được nhìn thấy những tác phẩm đa màu sắc của các em, thấy hài lòng với công việc của mình khi thấy các em giao tiếp vui vẻ với giáo viên của mình bằng những nội dung được chúng tôi soạn thảo."
      }
    },
    "env": {
      "title": "Tạo môi trường để mọi người có thể đắm chìm vào nó.",
      "step1": {
        "title": "Trưởng thành cùng với công ty",
        "description": "Hãy gửi bản giới thiệu cá nhân và lý lịch theo mẫu tự do của bạn qua Email cho chúng tôi nhé! Chúng tôi sẽ xem xét kỹ lưỡng hồ sơ ứng tuyển của các bạn."
      },
      "step2": {
        "title": "Giao tiếp và quan tâm lẫn nhau",
        "description": "Chúng tôi sẽ đánh giá khả năng thích ứng với công việc của bạn thông qua hình thức phỏng vấn Online hoặc Offline. Đối với nhóm ngành Design/lập trình có thể sẽ có những bài thi cụ thể được đưa ra."
      },
      "step3": {
        "title": "Chúng tôi cố gắng để tạo ra nơi làm việc vui vẻ nhất.",
        "description": "Chúng tôi tôn trọng và quan tâm đến đồng nghiệp, khách hàng để có thể thấu hiểu lẫn nhau. Luôn luôn cám ơn người bên cạnh, trân trọng cuộc sống mình đang có."
      }
    },
    "current": {
      "title": "Thông báo đang tiến hành",
      "letter": "Nhận thông báo tuyển dụng",
      "registerEmail": "Đăng ký qua email",
      "empty": "Không có thông báo tuyển dụng."
    }
  },
  "emailLetter": {
    "title": "Chúng mình nhất định sẽ cho các bạn biết!",
    "description": "Vui lòng để lại địa chỉ email.<br/>Khi nào chuẩn bị xong tôi sẽ liên lạc ngay!",
    "placeholder": "Để lại địa chỉ email",
    "guide": "Bằng cách chọn nhận thông báo, bạn đồng ý với chính sách bảo mật của công ty chúng tôi, i-Scream arts Co.,Ltd. Nếu các bạn không muốn thì cứ nói nhé.",
    "submit": "Nhận thông tin tuyển dụng.",
    "processing": "Đang xử lý..",
    "completeRegister": "Đã đăng ký email rồi.",
    "existRegister": "Đã đăng ký rồi."
  },
  "communication": {
    "title": "FAQ",
    "description": "Chúng tôi là tổ chức công nghệ sáng tạo chuyên cung cấp dịch vụ nghệ thuật toàn cầu cho tất cả trẻ em, thế hệ có quyền tận hưởng mỹ thuật trên toàn thế giới.",
    "idea": {
      "title": "Bạn có ý tưởng độc đáo?",
      "description": "Với những ý tưởng được lựa chọn chúng tôi sẽ đồng hành để hiện thực hóa và đền đáp xứng đáng",
      "register": "Đề xuất ý tưởng"
    }
  },
  "family": {
    "title": "Giới thiệu công ty gia đình của chúng tôi",
    "sigongTech": {
      "title": "SIGONGtech",
      "description": "SIGONGtech là một công ty văn hóa triển lãm chuyên biệt vượt ra khỏi tầm 'tốt nhất' trong nước thông qua bí quyết tích lũy hơn 30 năm trong lĩnh vực bảo tàng và phòng triển lãm."
    },
    "iceMedia": {
      "title": "i-Scream media",
      "description": "i-Scream media là một công ty giáo dục toàn cầu khám phá và tạo ra các giá trị mới từ nền tảng giáo dục và học tập kỹ thuật số đến các dịch vụ AI / dữ liệu lớn."
    },
    "sigongMunhwa": {
      "title": "SIGONGmunhwa",
      "description": "SIGONGmunhwa là một công ty chuyên về môi trường bảo tồn triển lãm chịu trách nhiệm từ A đến Z về môi trường bảo tồn, chẳng hạn như tư vấn, thiết kế, xây dựng và quản lý theo dõi các tài sản văn hóa."
    },
    "iceKids": {
      "title": "i-Scream kids",
      "description": "i-Scream kids là một công ty chuyên về giáo dục mầm non, dẫn đầu ngành kinh doanh mầm non toàn cầu bằng cách thiết lập các cơ chế giáo dục mầm non như tài liệu giáo sư giáo dục mầm non, mã hóa và nội dung AI."
    },
    "sigongTectCN": {
      "title": "文博时空科技有限公司",
      "description": "文博时空科技有限公司 là một liên doanh của SIGONGtech có trụ sở tại Trung Quốc, đã tạo nên một lịch sử mới trong ngành kinh doanh triển lãm và văn hóa Trung Quốc."
    },
    "iceEdu": {
      "title": "i-Scream edu",
      "description": "i-Scream edu là một công ty con của công ty triển lãm và công nghiệp văn hóa lớn nhất Hàn Quốc, Sigongtech. Đó là một công ty giáo dục theo đuổi sự phát triển tài năng toàn cầu."
    }
  }
}
