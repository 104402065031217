export async function updateDB(key, src, lang)
{
  return await $fetch('/api/db-update', {
    method: 'post',
    responseType: 'json',
    body: {
      key,
      value: JSON.stringify(src),
      dev: process.dev,
      lang,
    },
  })
}
