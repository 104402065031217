import { updateDB } from '~/libs/store.js'

export const blankFaqGroup = {
  id: '0',
  label: '',
  children: [
    {
      id: '0',
      title: '',
      items: [
        {
          id: '',
          label: '',
          body: '',
        },
      ]
    },
  ]
}

export const faqStore = defineStore('faq', {
  state: () => {
    return {
      groups: [],
    }
  },
  actions: {
    async save(src, lang = 'ko')
    {
      this.groups = src
      this.groups.forEach((group, groupKey) => {
        group.id = String(groupKey)
        group.children.forEach((section, sectionKey) => {
          section.id = String(sectionKey)
          section.items.forEach((item, itemKey) => {
            item.id = String(itemKey)
          })
        })
      })
      await updateDB('faq', this.groups, lang)
    },
  },
})
