{
  "base": {
    "pabloarts": "i-Scream arts Co.,Ltd.",
    "kakaoChannel": "kakao Channel",
    "contactUs": "Contact us",
    "instagram": "Instagram",
    "brandResource": "Brand Resources",
    "comingSoon": "Coming Soon",
    "gotoSite": "Visit Website",
    "family": "Family Companies",
    "doitLater": "Please try again in a few minutes later",
    "artbonbon": "artbonbon"
  },
  "meta": {
    "title": "i-Scream Arts Co.,Ltd.",
    "keywords": "i-Scream arts Co.,Ltd., i-Scream, i-Scream arts, art education company, Art Bonbon company, digital education for future leaders, art edutech, AI big data platform, child art, i-Scream arts Center",
    "description": "i-Scream arts Inc. \t&#124; A New Field of Education With Art"
  },
  "footer": {
    "address": "i-Scream arts Co.,Ltd., 18F, Teheran-ro 512, Gangnam-gu, Seoul, Republic of Korea",
    "tel": "Tel",
    "contactUs": "Contact us"
  },
  "company": {
    "jumbotron": {
      "introduce1": "",
      "introduce2": "We are an educational tech company that strives to instill in children the desire to lead a life that sets a good example by leading by example.",
      "nextSection": "Go To Next"
    },
    "philosophy": {
      "headDescription": "Company Philosophy",
      "headTitle": "From birth, every child is an artist.",
      "description": "From birth, every child is an artist.<br/>Every child express their inner feelings and purifying emotions by doodling, expressing, creating and breaking something from the time the tool is available. Children use art to express their thoughts and feelings. It is the most effective means of communicating with others."
    },
    "question": "<span>\"Art is the key to your mind.\"<br></span><span style='font-size:55%; line-height:80%'>Art is the ideal medium for expressing your creativity<br>because it\nintuitively communicates your innermost thoughts.</span>",
    "questionMobile": "<span>\"Art is the key<br>to your mind.\"<br></span><span style='font-size:55%; line-height:80%'>Art is the ideal medium<br>for expressing your creativity<br>because it\nintuitively communicates your innermost thoughts.</span>",
    "motivation": {
      "headDescription": "Work Motivation",
      "headTitle": "Numerous studies point to art as a source of inspiration and creativity.",
      "resource": "References",
      "resourceDescription0": "Barrett, Lisa Feldman. “Seven and a Half Lessons about the Brain”. 2021",
      "resourceDescription1": "Lee, Boo Yun. “A Study on The importance of Brain Development through Convergence of Art with STEAM Education”. KOREA SCIENCE & ART FORUM. 2014",
      "resourceDescription2": "Edwards, Betty. “Inner Imagery, Drawing on the Right Side of the Brain”. 2013",
      "resourceDescription3": "Gardner, H. “Multiple Intelligence”. 2007",
      "description": "Art conveys meaning through images rather than words, which allows for\nthe unrestricted expansion of ideas. Image is the source of imagination,\nand imagination is intimately related to creativity. This is why it is crucial\nto improve your mental health from a young age by broadening your\nviews.",
      "sectionTitle": "Creative, convergent art education for native digital users",
      "sectionDescription": "Digital literacy, as a fundamental skill for locating relevant information\nand utilizing it on multiple digital platforms, is gaining importance in\nthe present day. This indicates that the significance of the ability to\nthink visually increases with time. It is quite difficult to convey creative\nknowledge in clear and concise language. Nevertheless, it is feasible\nwith art. The digital age necessitates more art and digital art instruction."
    },
    "competence": {
      "headDescription": "Core Competence",
      "headTitle": "Our Core Competence",
      "section1": {
        "title": "The Path We Will Take",
        "word1": "Arts Education for World Youth",
        "word2": "Develop creativity and imagination",
        "word3": "Develop communication skills",
        "word4": "Identifying talents, aptitudes, career paths, and abilities of children",
        "word5": "Creating a global arts gateway for all children",
        "word6": "Improving children's digital literacy",
        "word7": "Art as a means of keeping children healthy"
      },
      "section2": {
        "title": "Technology (especially intellectual property protection)",
        "word1": "Drawing Tool",
        "word2": "Real Time Platform",
        "word3": "Interactive Curriculum",
        "word4": "AI Technology"
      },
      "section3": {
        "title": "Differentiated Education Curriculum",
        "word1": "Global curriculum by age",
        "word2": "Interactive Contents(Copyright registrated)",
        "word3": "Creativity Development Curriculum",
        "word4": "Diverse curriculum with art-based science, history, environment, nature, and personality development"
      }
    },
    "artBonbon": {
      "title": "We’re<br/>Art Bonbon",
      "readMore": "See more",
      "introductionPDF": "Introduction(PDF)",
      "freeTrial": "Drawing Tool\nFree Trial(iOS)",
      "description": "For the first time in the world, i-Scream arts Co.,Ltd. has introduced Art Bonbon, a global creative convergence education platform.<br/>\nArt Bonbon is more than just an art education service.<br/>We cultivate talent by providing cultural, art, science, mathematics, and humanities students with 'technical-based aesthetic art activities' that encourage their creative expression.<br/><br/>Art Bonbon is a 1:1 or 1:N real-time education service.<br/>There are age-specific selection courses for brain, technique, and mind."
    },
    "curiosity": {
      "title": "A Completely Fresh Start for Creativity",
      "description": "In the future, our children will rule the globe with creative ideas.<br/>Art BonBon seeks to assist children extend their imaginations without\nbounds via the use of art that fosters creativity and imagination in early\nlife. We genuinely hope that our education would enable the youngsters\nto become innovative global leaders.<br/><br/>Through art, we are poised to maximize children's creativity and imagination, as well as develop a super-personalized education curriculum based on the world's first patented real-time education data utilizing experts and AI technology.",
      "readMore": "More"
    },
    "press": {
      "title": "i-Scream arts In Press",
      "more": "Read more articles",
      "empty": "There is no article now."
    },
    "language": {
      "ko": "Korean",
      "en": "English"
    }
  },
  "brand": {
    "title": "Introducing i-Scream arts Co.,Ltd.'s CI & BI.",
    "download": {
      "ai": "AI Download",
      "pdf": "PDF Download",
      "korean": "Downlooad(KOR)",
      "english": "Downlooad(ENG)"
    },
    "ci": {
      "title": "i-Scream arts Co.,Ltd.",
      "description": "In addition to providing future-oriented education, i-Scream arts Co.,Ltd. strives to cultivate creative thinking and a healthy mind through art and dialogue, not just through art education. Online art education service Art Bonbon, online/offline art education franchise i-Scream arts Center, Gangnam Daechidong/Pangyo i-Scream arts Center are in operation."
    },
    "bi": {
      "section1Title": "Art Bonbon",
      "section1Description": "The French word 'bonbon' is formed by overlapping the letters 'bon' and 'bon', meaning 'good'. An adorable word for candy, chocolate, and jelly. We will make a concerted effort to ensure that children are able to think and express themselves creatively through art, as well as design their dreams and achieve their happiness freely.",
      "section2Title": "i-Scream arts center",
      "section2Description": "i-Scream arts Center is a franchise offering a wide range of art education online/offline that combines Jewish Havruta with a curriculum that develops self-efficacy by encouraging free-expression. Making Art Bonbon the first on/off franchise in Korea, the program offers digital art education as well as offline curriculum."
    }
  },
  "recruit": {
    "wanted": {
      "title": "It is our desire to recruit talents to contribute to the world's first global art service.",
      "description": "Korea has pioneered the first ever digital global art education in the world.<br/>In addition to simple art education, we incorporate elements of personality, science, the environment, cosmology and philosophy. Our core competitive advantage resides in our own technology and creative content.<br/><br/><br/>Digital i-Scream arts' X Art Bonbon services are intended to improve children's digital literacy competitiveness and to reach out to youth around the globe with positive messages.",
      "howto": "How we work"
    },
    "cards": {
      "people0": {
        "name": "Eunhee Park, CS Manager",
        "team": "CS Team",
        "description": "Representing the company's service, we are striving for empathic communication by listening to the voice of the customers."
      },
      "people1": {
        "name": "Solhee Kim, Marketing Assistant",
        "team": "Strategic Marketing Team",
        "description": "Outstanding opportunity to develop Art and IT area’s marketing capability either, I'm building up an extraordinary career."
      },
      "people2": {
        "name": "Minhee Lee, Video Editor",
        "team": "Live Commerce Team",
        "description": "Everytime I create lively and creative video content, I get positive energy!"
      },
      "people3": {
        "name": "Jaekoo Kang, AI Researcher",
        "team": "AI Lab",
        "description": "Our goal is to create artificial intelligence that fosters and analyzes children's creativity, imagination, and expression."
      },
      "people4": {
        "name": "Geumseo Nam, UX/UI Designer",
        "team": "Design Team",
        "description": "Combining art and science to create services that children and adults can use together is an enjoyable experience!"
      },
      "people5": {
        "name": "Jiwon Hu, Contents Manager",
        "team": "Content Management Team",
        "description": "It will be a rewarding experience to see the children's colorful creations while communicating happily with the teacher via planned activities."
      }
    },
    "env": {
      "title": "We Provide You A Productive workspace",
      "step1": {
        "title": "Grow with the company",
        "description": "Send us your CV in any format of your choice with a cover letter by email. We promise to carefully review your valuable documents."
      },
      "step2": {
        "title": "Communication and consideration",
        "description": "Job suitability is assessed through face-to-face or remote interviews. Assignments may be required for design/development positions."
      },
      "step3": {
        "title": "We build a happy workplace",
        "description": "We are considerate and respectful towards our colleagues and customers for a truthful connection. It is important to us to appreciate our neighbors and to cherish our relationship together."
      }
    },
    "current": {
      "title": "We're Hiring",
      "letter": "Get job postings",
      "registerEmail": "Email Application",
      "empty": "There are no job postings now."
    }
  },
  "emailLetter": {
    "title": "We'll let you know certainly!",
    "description": "Enter your email to receive our latest job openings!",
    "placeholder": "Submit",
    "guide": "If you get notified, you are considered to have agreed to our privacy policy. You can let us know whenever you don't want to.",
    "submit": "Get notified",
    "processing": "In Progress...",
    "completeRegister": "Success! You're now subscribed.",
    "existRegister": "You're already subscribed."
  },
  "communication": {
    "title": "FAQ",
    "description": "It is a group of creative technology experts providing global art services to children who have the right to enjoy art.",
    "idea": {
      "title": "Any good ideas?",
      "description": "Proposals that are accepted are concretely developed and rewarded.",
      "register": "Make a suggestion"
    }
  },
  "family": {
    "title": "Introducing Our Family Companies",
    "sigongTech": {
      "title": "SIGONGtech",
      "description": "SIGONGtech is an exhibition culture company that extends beyond the 'best' in Korea through know-how accumulated over 30 years in museums and exhibition halls."
    },
    "iceMedia": {
      "title": "i-Scream media",
      "description": "i-Screammedia is a global edutech company that discovers and creates new values from digital teaching and learning platform to AI/Big data services."
    },
    "sigongMunhwa": {
      "title": "SIGONGmunhwa",
      "description": "SIGONGmunhwa is an exhibition conservation environment company that is responsible for A-Z of conservation environment such as consulting, design, construction, and post-management of cultural properties."
    },
    "iceKids": {
      "title": "i-Scream kids",
      "description": "i-Scream kids is a company specializing in early childhood education that leads the global early childhood business by establishing early childhood education mechanisms such as teaching materials, coding, and AI content."
    },
    "sigongTectCN": {
      "title": "文博时空科技有限公司",
      "description": "文博时空科技有限公司 is a joint venture in China by SIGONGtech that is creating a new history of China's exhibition and culture business."
    },
    "iceEdu": {
      "title": "i-Scream edu",
      "description": "i-Scream edu is a subsidiary of Korea's largest exhibition and cultural industry company, SIGONGtech. It is an educational company that pursues global talent development."
    }
  }
}
