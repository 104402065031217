import { createI18n } from 'vue-i18n'
import localeKO from '../locales/ko.json'
import localeEN from '../locales/en.json'
import localeVN from '../locales/vn.json'

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'ko',
    messages: {
      ko: localeKO,
      en: localeEN,
      vn: localeVN,
    }
  })
  vueApp.use(i18n)
})
