import { updateDB } from '~/libs/store.js'

export const blankPressItem = {
  id: '',
  link: '',
  title: '',
  date: '',
}

export const pressStore = defineStore('press', {
  state: ()  => {
    return {
      page: 1,
      size: 3,
      items: [],
    }
  },
  getters: {
    currentItems()
    {
      return this.items.slice(0, this.page * this.size)
    },
    remain()
    {
      return this.items.length - (this.page * this.size) > 0
    },
  },
  actions: {
    more()
    {
      this.page++
    },
    reset()
    {
      this.page = 1
    },
    async remove(key, lang = 'ko')
    {
      this.items.splice(key, 1)
      await updateDB('press', this.items, lang)
    },
    async save(src, lang = 'ko')
    {
      this.items = src
      this.items.forEach((item, key) => {
        item.id = String(key)
      })
      let res = await updateDB('press', this.items, lang)
      if (!res.success) throw new Error(res.message)
    },
  },
})
