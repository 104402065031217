/**
 * new tab link
 */
export function newLink(url)
{
  const pop = window.open(url)
  try
  {
    pop.focus()
  }
  catch (e)
  {
    alert(`브라우저 팝업차단을 해제해주세요.`)
  }
}

/**
 * sleep (delay tool)
 */
export function sleep(ms = 1000)
{
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function pureObject(src)
{
  if (!src) return null
  try
  {
    return JSON.parse(JSON.stringify(src))
  }
  catch (_) {
    return undefined
  }
}
