import { marked } from 'marked'
import { useI18n } from 'vue-i18n'
import { updateDB } from '~/libs/store.js'

export const blankGroup = {
  id: '',
  label: '',
  items: [
    {
      id: '',
      title: '',
      meta: [ '', '' ],
      email: '',
      body: '',
    }
  ]
}

export const recruitStore = defineStore('recruit', {
  state: () => {
    return {
      progress: [],
    }
  },
  getters: {
    parseProgress()
    {
      return this.progress.map(position => ({
        ...position,
        items: position.items.map(item => {
          return {
            ...item,
            body: marked(item.body),
          }
        }),
      }))
    },
  },
  actions: {
    async save(src, lang = 'ko')
    {
      this.progress = src
      this.progress.forEach((group, groupKey) => {
        group.id = String(groupKey)
        group.items.forEach((item, itemKey) => {
          item.id = String(itemKey)
        })
      })
      await updateDB('recruit', this.progress, lang)
    },
  },
})

export const wantedCardsStore = defineStore('recruitWanted', {
  state: () => {
    const { t } = useI18n()
    return {
      items: [
        {
          name: t('recruit.cards.people0.name'),
          team: t('recruit.cards.people0.team'),
          description: t('recruit.cards.people0.description'),
          avatar: '/images/avatar/pablo-avatar-15.svg',
        },
        {
          name: t('recruit.cards.people1.name'),
          team: t('recruit.cards.people1.team'),
          description: t('recruit.cards.people1.description'),
          avatar: '/images/avatar/pablo-avatar-11.svg',
        },
        {
          name: t('recruit.cards.people2.name'),
          team: t('recruit.cards.people2.team'),
          description: t('recruit.cards.people2.description'),
          avatar: '/images/avatar/pablo-avatar-20.svg',
        },
        {
          name: t('recruit.cards.people3.name'),
          team: t('recruit.cards.people3.team'),
          description: t('recruit.cards.people3.description'),
          avatar: '/images/avatar/pablo-avatar-25.svg',
        },
        {
          name: t('recruit.cards.people4.name'),
          team: t('recruit.cards.people4.team'),
          description: t('recruit.cards.people4.description'),
          avatar: '/images/avatar/pablo-avatar-13.svg',
        },
        {
          name: t('recruit.cards.people5.name'),
          team: t('recruit.cards.people5.team'),
          description: t('recruit.cards.people5.description'),
          avatar: '/images/avatar/pablo-avatar-10.svg',
        },
      ],
    }
  },
  actions: {
    refresh(t)
    {
      this.items = this.items.map((item, key) => ({
        ...item,
        name: t(`recruit.cards.people${key}.name`),
        team: t(`recruit.cards.people${key}.team`),
        description: t(`recruit.cards.people${key}.description`),
      }))
    }
  },
})
