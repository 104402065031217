import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/app/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.mjs";
import node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.mjs";
import plugins_i18n_js_sVHQBgnb3t from "/app/plugins/i18n.js";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_plugins_router_mjs_Pg0DINazwm,
  node_modules_nuxt_dist_pages_runtime_plugins_prefetch_client_mjs_3cwHvxIDEX,
  plugins_i18n_js_sVHQBgnb3t
]