<template>
<nuxt-layout>
  <nuxt-page/>
</nuxt-layout>
</template>

<script setup>
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { pressStore } from '~/store/company.js'
import { recruitStore } from '~/store/recruit.js'
import { faqStore } from '~/store/communication.js'
import { layoutStore } from '~/store/layout.js'
import { pureObject } from '~/libs/util.js'

const nuxtApp = useNuxtApp()
const route = useRoute()
const { locale } = useI18n()
const press = pressStore()
const recruit = recruitStore()
const faq = faqStore()
const layout = layoutStore()

nuxtApp.hook('page:finish', () => {
  window.scrollTo(0, 0)
})

onMounted(() => {
  document.body.setAttribute('ontouchstart', '')
  // Create the GTM script element
  const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MG826X8X');
    `;
  document.head.appendChild(script);
  // Create the noscript element
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MG826X8X"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.appendChild(noscript);
})

function setupStore(json)
{
  if (!json) return
  press.items = json.press || []
  recruit.progress = json.recruit || []
  faq.groups = json.faq || []
  locale.value = layout.lang
}

// set locale
const langCookie = useCookie('lang')
if (langCookie.value) layout.lang = langCookie.value

// service setup
if (!/^\/manager/.test(route.path))
{
  const { data } = await useAsyncData('setup', () => $fetch('/api/db-get', {
    method: 'post',
    body: { lang: layout.lang },
  }))
  if (data.value)
  {
    setupStore(pureObject(data.value.json));
  }
  else
  {
    throw new Error('no data');
  }
}
</script>
