import { default as pages_47brand_45resource_47index_46vueMeta } from "/app/pages/brand-resource/index.vue?macro=true";
import { default as pages_47communication_47index_46vueMeta } from "/app/pages/communication/index.vue?macro=true";
import { default as pages_47family_47index_46vueMeta } from "/app/pages/family/index.vue?macro=true";
import { default as pages_47index_46vueMeta } from "/app/pages/index.vue?macro=true";
import { default as pages_47manager_47etc_47index_46vueMeta } from "/app/pages/manager/etc/index.vue?macro=true";
import { default as pages_47manager_47faq_47index_46vueMeta } from "/app/pages/manager/faq/index.vue?macro=true";
import { default as pages_47manager_47index_46vueMeta } from "/app/pages/manager/index.vue?macro=true";
import { default as pages_47manager_47login_46vueMeta } from "/app/pages/manager/login.vue?macro=true";
import { default as pages_47manager_47press_47index_46vueMeta } from "/app/pages/manager/press/index.vue?macro=true";
import { default as pages_47manager_47recruit_45letter_47index_46vueMeta } from "/app/pages/manager/recruit-letter/index.vue?macro=true";
import { default as pages_47manager_47recruit_47index_46vueMeta } from "/app/pages/manager/recruit/index.vue?macro=true";
import { default as pages_47recruit_47index_46vueMeta } from "/app/pages/recruit/index.vue?macro=true";
export default [
  {
    name: pages_47brand_45resource_47index_46vueMeta?.name ?? "brand-resource",
    path: pages_47brand_45resource_47index_46vueMeta?.path ?? "/brand-resource",
    children: [],
    meta: pages_47brand_45resource_47index_46vueMeta,
    alias: pages_47brand_45resource_47index_46vueMeta?.alias || [],
    redirect: pages_47brand_45resource_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/brand-resource/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47communication_47index_46vueMeta?.name ?? "communication",
    path: pages_47communication_47index_46vueMeta?.path ?? "/communication",
    children: [],
    meta: pages_47communication_47index_46vueMeta,
    alias: pages_47communication_47index_46vueMeta?.alias || [],
    redirect: pages_47communication_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/communication/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47family_47index_46vueMeta?.name ?? "family",
    path: pages_47family_47index_46vueMeta?.path ?? "/family",
    children: [],
    meta: pages_47family_47index_46vueMeta,
    alias: pages_47family_47index_46vueMeta?.alias || [],
    redirect: pages_47family_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/family/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47index_46vueMeta?.name ?? "index",
    path: pages_47index_46vueMeta?.path ?? "/",
    children: [],
    meta: pages_47index_46vueMeta,
    alias: pages_47index_46vueMeta?.alias || [],
    redirect: pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47etc_47index_46vueMeta?.name ?? "manager-etc",
    path: pages_47manager_47etc_47index_46vueMeta?.path ?? "/manager/etc",
    children: [],
    meta: pages_47manager_47etc_47index_46vueMeta,
    alias: pages_47manager_47etc_47index_46vueMeta?.alias || [],
    redirect: pages_47manager_47etc_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/etc/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47faq_47index_46vueMeta?.name ?? "manager-faq",
    path: pages_47manager_47faq_47index_46vueMeta?.path ?? "/manager/faq",
    children: [],
    meta: pages_47manager_47faq_47index_46vueMeta,
    alias: pages_47manager_47faq_47index_46vueMeta?.alias || [],
    redirect: pages_47manager_47faq_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/faq/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47index_46vueMeta?.name ?? "manager",
    path: pages_47manager_47index_46vueMeta?.path ?? "/manager",
    children: [],
    meta: pages_47manager_47index_46vueMeta,
    alias: pages_47manager_47index_46vueMeta?.alias || [],
    redirect: pages_47manager_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47login_46vueMeta?.name ?? "manager-login",
    path: pages_47manager_47login_46vueMeta?.path ?? "/manager/login",
    children: [],
    meta: pages_47manager_47login_46vueMeta,
    alias: pages_47manager_47login_46vueMeta?.alias || [],
    redirect: pages_47manager_47login_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/login.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47press_47index_46vueMeta?.name ?? "manager-press",
    path: pages_47manager_47press_47index_46vueMeta?.path ?? "/manager/press",
    children: [],
    meta: pages_47manager_47press_47index_46vueMeta,
    alias: pages_47manager_47press_47index_46vueMeta?.alias || [],
    redirect: pages_47manager_47press_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/press/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47recruit_45letter_47index_46vueMeta?.name ?? "manager-recruit-letter",
    path: pages_47manager_47recruit_45letter_47index_46vueMeta?.path ?? "/manager/recruit-letter",
    children: [],
    meta: pages_47manager_47recruit_45letter_47index_46vueMeta,
    alias: pages_47manager_47recruit_45letter_47index_46vueMeta?.alias || [],
    redirect: pages_47manager_47recruit_45letter_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/recruit-letter/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47manager_47recruit_47index_46vueMeta?.name ?? "manager-recruit",
    path: pages_47manager_47recruit_47index_46vueMeta?.path ?? "/manager/recruit",
    children: [],
    meta: pages_47manager_47recruit_47index_46vueMeta,
    alias: pages_47manager_47recruit_47index_46vueMeta?.alias || [],
    redirect: pages_47manager_47recruit_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/manager/recruit/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47recruit_47index_46vueMeta?.name ?? "recruit",
    path: pages_47recruit_47index_46vueMeta?.path ?? "/recruit",
    children: [],
    meta: pages_47recruit_47index_46vueMeta,
    alias: pages_47recruit_47index_46vueMeta?.alias || [],
    redirect: pages_47recruit_47index_46vueMeta?.redirect || undefined,
    component: () => import("/app/pages/recruit/index.vue").then(m => m.default || m)
  }
]